import store from '@/state/store'


export default [
    // Páginas do usuário login, logout, register, forgot-password
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['autheasy/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' });
                } else {
                    // Continue to the login page
                    next();
                }
            },
            roles: ['super', 'partner', 'admin', 'user', 'professional'],
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['autheasy/loggedIn']) {
                    store.dispatch('autheasy/resetpassWord');
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    store.dispatch('autheasy/resetpassWord');
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin'],
        },
        component: () => import('../views/pages/account/register'),
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    if (process.env.VUE_APP_DEFAULT_AUTH === "easyindustria-api") {
                        store.dispatch('autheasy/loGout')
                    } else {
                        store.dispatch('authfack/logout')
                    }
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },

    // Página inicial Dashboard
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/dashboard/index')
    },

    // Páginas de Agenda do Profissional
    {
        path: '/professional/calendar',
        name: 'professional-calendar',
        component: () => import('../views/pages/profissionais/index_logged'),
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user', 'professional'],
        },
    },

    // Demais páginas do sistema
    {
        path: '/cadastros/abordagem',
        name: 'abordagem',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/abordagens/index')
    },
    {
        path: '/financeiro/agenciaBanc',
        name: 'agencia',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/financeiro/agenciaBanc/index')
    },
    {
        path: '/atendimentos',
        name: 'Atendimentos',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/atendimentos/index')
    },
    {
        path: '/financeiro/bancos',
        name: 'bancos',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/financeiro/bancos/index')
    },
    {
        path: '/cadastros/calendario',
        name: 'calendario',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/calendario/index')
    },
    {
        path: '/parametros/centrocusto',
        name: 'parametros-centrocusto',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/parametros/centrocusto/index')
    },
    {
        path: '/parametros/centroresultado',
        name: 'parametros-centroresultado',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/parametros/centroresultado/index')
    },
    {
        path: '/parametros/cidades',
        name: 'parametros-cidades',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/parametros/cidades/index')
    },
    {
        path: '/cadastros/convenio',
        name: 'convenio',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/plano/index')
    },
    {
        path: '/cadastros/empresas',
        name: 'Empresas',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin'],
        },
        component: () => import('../views/pages/empresa/index')
    },
    {
        path: '/relatorios/ficha.vue',
        name: 'Ficha',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/relatorios/ficha.vue')
    },
    {
        path: '/parametros/historicopadrao',
        name: 'parametros-historicopadrao',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/parametros/historicopadrao/index')
    },
    {
        path: '/cadastros/pacientes',
        name: 'Pacients',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/pacientes/index')
    },
    {
        path: '/cadastros/profissionais',
        name: 'Professionals',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/profissionais/index')
    },
    {
        path: '/cadastros/responsibles',
        name: 'Responsibles',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/responsible/index')
    },
    {
        path: '/select-company',
        name: 'Select-company',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user', 'professional'],
        },
        component: () => import('../views/pages/account/select-company'),
    },
    {
        path: '/parametros/tipospagamento',
        name: 'parametros-tipospagamento',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/parametros/tipospagamento/index')
    },
    {
        path: '/cadastros/tratamento',
        name: 'tratamento',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/tratamento/index')
    },
    {
        path: '/parametros/unidademed',
        name: 'parametros-unidademed',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin', 'user'],
        },
        component: () => import('../views/pages/parametros/unidademed/index')

    },
    {
        path: '/userGetAll',
        name: 'userGetAll',
        meta: {
            authRequired: true,
            roles: ['super', 'partner', 'admin'],
        },
        component: () => import('../views/pages/account/getall')
    },
    {
        path: '/pacient',
        name: 'pacient',
        component: () => import('../views/layouts/main.vue'),
        meta: { authRequired: true },
        children: [
            {
                path: 'profile',
                name: 'pacient-profile',
                component: () => import('../views/pages/pacientes/profile.vue'),
                meta: {
                    authRequired: true,
                    pageTitle: 'Perfil do Paciente'
                }
            }
        ]
    },
]
