<script>
import appConfig from "@/app.config";
import { http } from '@/helpers/easyindustriaapi/config';
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import Calendar from "./calendar";
import Swal from "sweetalert2";
import moment from "moment";

export default {
  page: {
    title: 'Calendario',
    meta: [ {name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, Calendar },
  data() {
    return {
      vet_profissional:[],
      vet_responsible:[],
      vet_pacientes:[],
      currentLocalEstoque: {},
      agendas: [],
      showCalendar: false,
      editando: false,
      titleBody: 'Agenda',
      items: [
        {
        text: "Dashboard",
        href: "/",
        },
        {
        text: "Parâmetros",
        href: "/",
        active: true,
        },
        {
        text: "Agenda",
        href: "cadastros/calendario",
        active: true,
        }
      ],
      vet_specialtys:[]
    }
  },
  computed: {
    isHide() {
      return (!this.showCalendar && !this.editando);
    },
  },
  created() {
    this.currentUser    = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
  },
  mounted() {
    this.getData();
  },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    changeHomeTitle(newTitle) {
      this.titleBody = newTitle;
    },
    back() {
      this.showCalendar = false;
      this.editando = false;
    },
    onLoader() {
      if (document.getElementById("preloader").style.display === "none")
      {
        document.getElementById("preloader").style.display = "block";
        document.getElementById("status").style.display = "block";
      }
    },
    offLoader() {
      if (document.getElementById("preloader").style.display === "block")
      {
        setTimeout(function () {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }, 2500);
      }
    },
    async getData() {
      this.showCalendar = false
      // Exibe o SweetAlert com loader
      Swal.fire({
        title: 'Aguarde...',
        text: 'Carregando tabelas...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading(); // Exibe o loader enquanto a requisição está em andamento
        }
      });

      const promisse = http.get(`/agenda/getTables?empresa_id=${this.currentEmpresa.id}`);
      return promisse.then((response) => {
        this.agendas          = response.data.agendas;
        this.vet_pacientes    = response.data.pacients;
        this.vet_responsible  = response.data.responsibles;
        this.vet_profissional = response.data.professionals;
        this.vet_specialtys = response.data.specialtys;
        this.showCalendar = true;
      }).catch((error) => {
        Swal.close(); // Fecha o loader
        console.log(error);
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;
        
          default:
            this.makeToast('danger', error.message);
            break;
        }
      })
      .finally(() => {
        Swal.close(); // Fecha o loader
        this.offLoader();
      });
    },
    async get_paciente() {
      this.onLoader();
      try {
        let response = await http.get('/pacient?empresa_id=' + this.currentEmpresa.id);

        if (response.status === 200) {
          this.vet_pacientes = response.data;
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            this.offLoader();
            break;
        
          default:
            this.makeToast('danger', error.message);
            this.offLoader();
            break;
        }
      }
    },
    async get_resposible() {
      this.onLoader();
      try {
        let response = await http.get('/responsible?empresa_id=' + this.currentEmpresa.id);

        if (response.status === 200) {
          this.vet_responsible = response.data;
          console.log(this.agendas)
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            this.offLoader();
            break;
        
          default:
            this.makeToast('danger', error.message);
            this.offLoader();
            break;
        }
      }
    },
    tempo(){
      setTimeout(()=>{
        this.showCalendar = true;
      },4000);
    },
    async get_profissional() {
      this.onLoader();
      try {
        let response = await http.get('/professional?empresa_id=' + this.currentEmpresa.id);

        if (response.status === 200) {
          this.vet_profissional = response.data;
          console.log(this.agendas)
          this.tempo();
          this.offLoader();
        }
      } catch (error) {
        let messageErro = error.response.data;

        switch (error.response.status) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + (messageErro.tipo) ? messageErro.tiponome : messageErro.tpag);
            this.offLoader();
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            this.offLoader();
            break;
        
          default:
            this.makeToast('danger', error.message);
            this.offLoader();
            break;
        }
      }
    },
    insert () {
      this.showCalendar = !this.showCalendar;
    },
    edit(local) {
      console.log(local);
      this.currentLocalEstoque = local;
      this.editando = !this.editando;
    },
    async doPost(plano) {
      plano.empresa_id = this.currentEmpresa.id;
      let response = await http.post('/agreement?empresa_id='+this.currentEmpresa.id, plano)
        .catch((error) => {
            this.makeToast('danger', error.response);
            this.offLoader();
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast('success', 'Registro incluído');
      }
    },
    async doPut(plano) {
      this.onLoader();
      plano.user_id = this.currentUser.id;
      let response = await http.put('/agreement/'+plano.id+'?empresa_id='+this.currentEmpresa.id, plano)
        .catch((error) => {
            this.offLoader();
            this.makeToast('danger', error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast('success', 'Registro alterado');
      }
    },
    async doDelete(agreement) {
      this.onLoader();
      agreement.empresa_id = this.currentEmpresa.id;
      let response = await http.delete('/agreement/'+agreement.id+'?empresa_id='+this.currentEmpresa.id, agreement)
        .catch((error) => {
          this.offLoader();
            this.makeToast('danger', error.response);
        });

      if (response.status === 200) {
        this.getData();
        this.back();
        this.makeToast('warning', 'Registro excluído');
      }
    },
    async doCancelCurrence(agenda) {
      console.log('noIndex', agenda);
      const evento = {
        id: agenda.id,
        start:        moment(agenda.dt_ini).format("yyyy-MM-DD"),
        end:          moment(agenda.dt_fin).format("yyyy-MM-DD"),
        start_time:   moment(agenda.dt_ini).format("HH:mm:ss"),
        end_time:     moment(agenda.dt_fin).format("HH:mm:ss"),
        is_cancelled: 1,
      };
      Swal.queue([
        {
          title: "CONFIRME",
          icon: "warning",
          html: "Deseja realmente excluir o <b>Atendimento</b>?",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          cancelButtonText: "Nao",
          confirmButtonText: "Sim, excluir!",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            const url = `/agenda/${evento.id}/cancelCurrence?empresa_id=${this.currentEmpresa.id}`;
            let promisse = http.post(url, evento);

            return promisse.then((response) => {
              if (response.status === 200) {
                Swal.insertQueueStep({
                  type: "success",
                  icon: "success",
                  title: "Sucesso!",
                  text: "Agendamento excluído com sucesso."
                });
                this.$bvModal.hide('modal-calendar-edit');
              } else {
                Swal.insertQueueStep({
                  type: "error",
                  icon: "warning",
                  title: "Erro ao excluir o Agendamento",
                  text: response.data
                });
              }
            })
            .catch((error) => {
              console.log('Error request', error);
              Swal.insertQueueStep({
                  type: "error",
                  icon: "warning",
                  title: "Erro ao excluir o Agendamento",
                  text: "Verifique o log do sistema!"
                });
            });
          }
        }
      ]);
    },
  },
}
</script>

<template>
<Layout>
  <PageHeader :title="titleBody" :items="items" />
  <Calendar
     v-if="showCalendar"
     :currentEmpresa="currentEmpresa"
     :vet_pacientes="vet_pacientes"
     :vet_responsible="vet_responsible"
     :vet_profissional="vet_profissional"
     :vet_specialtys="vet_specialtys"
     @newTitle="changeHomeTitle"
     @doPost="doPost"
     @doCancelCurrence="doCancelCurrence"
  />
</Layout>
</template>