<template>
    <div>
        <div class="row">
            <div class="col-md-6">
                <Label>Paciente</Label>
                <b-form-select
                    class="form-control"
                    id="formEventMeetPacient"
                    v-model="agenda.pacient_id"
                    :options="pacients"
                    value-field="id"
                    text-field="nome"
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled>-- Selecione o Paciente --</b-form-select-option>
                    </template>
                </b-form-select>
            </div>
            <div class="col-md-3">
                <Label>Dia:</Label>
                <b-form-datepicker v-model="agenda.day"
                    :date-disabled-fn="dateDisabled"
                    locale="pt-BR"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    placeholder="Data reunião"
                >
                </b-form-datepicker>
            </div>
            <div class="col-md-2">
                <Label for="formAgendaFim">Horário:</Label>
                <b-form-timepicker
                    v-model="agenda.hour"
                    locale="pt-BR"
                    placeholder="Hora"
                    label-hours="hora"
                    label-minutes="minuto"
                    label-no-time-selected="Selecione"
                    label-close-button="OK"
                    close-button-variant="success"
                    now-button
                    now-button-variant="primary"
                    label-now-button="Agora"
                ></b-form-timepicker>
            </div>
        </div>
        <div class="row mt-2 pt-2">
            <div class="col-md-12">
                <label class="col-md-12 col-form-label">Profissionais:</label>
            </div>
            <div class="col-12">
                <div class="form-group row"
                    v-for="(select, idx) in professionalSelects" :key="idx"
                >
                    <div class="col-md-10">
                        <b-form-select 
                            v-model="select.selectedProfessional"
                            :options="professionals"
                            @change="updateAgendaProfessionals"
                            value-field="id"
                            text-field="name"
                        >
                            <template #first>
                                <b-form-select-option :value="null" disabled>-- Selecione o profissional --</b-form-select-option>
                            </template>
                        </b-form-select>
                    </div>
                    <div class="col-md-2">
                        <button v-if="countSelectsProf != idx" @click="remProfessionalSelect(idx)" class="btn btn-outline-danger" type="button">-</button>
                        <button v-if="countSelectsProf == idx" @click="addProfessionalSelect" class="btn btn-outline-primary" type="button">+</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <button type="button" class="btn btn-success" @click="doPost">Gravar</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        agenda: {type: Object, required: true},
        pacients: {type: Array, required: true},
        professionals: {type: Array}
    },
    data() {
        return {
            professionalSelects: [{selectedProfessional: null}],
            countSelectsProf: 0,
            pacient: {},
            profissionais: [],
            labels: {
                'pt-br': {
                    labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren'
                }
            },
        }
    },
    created() {},
    mounted() {},
    methods: {
        dateDisabled(ymd, date) {
            // Disable weekends (Sunday = `0`, Saturday = `6`) and
            const weekday = date.getDay()
            // Return `true` if the date should be disabled
            return weekday === 0 || weekday === 6
        },
        addProfessionalSelect() {
            this.professionalSelects.push({
                selectedProfessional: null
            });
            this.countSelectsProf++;
        },
        remProfessionalSelect(idx) {
            this.countSelectsProf--;
            this.professionalSelects.splice(idx, 1);
            this.updateAgendaProfessionals();
        },
        updateAgendaProfessionals() {
            // Filtra apenas os profissionais selecionados (não nulos)
            const selectedProfessionals = this.professionalSelects
                .map(select => select.selectedProfessional)
                .filter(professional => professional !== null);
            
            this.agenda.professionals = selectedProfessionals;
            // Atualiza agenda.professionals
            this.$emit('update:professionals', selectedProfessionals);
        },
        doPost() {
            const selectedPacient = this.pacients.find(
                pacient => pacient.id === this.agenda.pacient_id
            );
            this.agenda.title = `REUNIÃO - Paciente ${selectedPacient.nome}`
            this.$emit('doMeet', this.agenda);
        }
    },
}
</script>