<script>
import appConfig from "@/app.config";
import {http} from '@/helpers/easyindustriaapi/config';
import Layout from "@/views/layouts/main"
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";

export default {
  page: {
    title: 'Perfil do Paciente',
    meta: [{
      name: 'description',
      content: appConfig.description
    }],
  },
  components: { PageHeader, Layout },
  data() {
    return {
      paciente: {},
      atendimentos: [],
      titleBody: 'Perfil do Paciente',
      items: [{
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Perfil",
          href: "/pacient/profile",
          active: true,
        }
      ]
    }
  },
  created() {
    this.currentUser = JSON.parse(localStorage.getItem('user'));
    this.currentEmpresa = JSON.parse(localStorage.getItem('currentEmpresa'));
  },
  mounted() {
    this.getPacienteData();
  },
  methods: {
    makeToast(variant = null, message = 'Error generic') {
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: 'b-toaster-bottom-right',
        variant: variant,
        solid: true,
        appendToast: true
      });
    },
    getPacienteData() {
      Swal.fire({
        title: 'Aguarde...',
        text: 'Carregando dados...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const promise = http.get(`/pacient/profile?empresa_id=${this.currentEmpresa.id}`);
      
      return promise.then((response) => {
        this.paciente = response.data.paciente;
        this.atendimentos = response.data.atendimentos;
        Swal.close();
      })
      .catch((error) => {
        Swal.close();
        let messageErro = "";
        console.log(error);
        
        if (error.response && error.response.data) {
          messageErro = error.response.data;
        }

        switch (error.response ? error.response.status : null) {
          case 406:
            this.makeToast('danger', 'Erro 406: ' + messageErro);
            break;
          case 404:
            this.makeToast('danger', 'Erro 404: endpoint não encontrado ou servidor fora do ar');
            break;
          default:
            this.makeToast('danger', error.message);
            break;
        }
      });
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="titleBody" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <!-- Card Informações Pessoais -->
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Informações Pessoais</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label">Nome Completo</label>
                  <p class="form-control-static">{{ paciente.name }}</p>
                </div>
                <div class="form-group">
                  <label class="form-label">CPF</label>
                  <p class="form-control-static">{{ paciente.cpf }}</p>
                </div>
                <div class="form-group">
                  <label class="form-label">Data de Nascimento</label>
                  <p class="form-control-static">{{ paciente.birth_date }}</p>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label">E-mail</label>
                  <p class="form-control-static">{{ paciente.email }}</p>
                </div>
                <div class="form-group">
                  <label class="form-label">Telefone</label>
                  <p class="form-control-static">{{ paciente.phone }}</p>
                </div>
                <div class="form-group">
                  <label class="form-label">Convênio</label>
                  <p class="form-control-static">{{ paciente.agreement?.name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Card Histórico de Atendimentos -->
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Histórico de Atendimentos</h4>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Profissional</th>
                    <th>Tipo de Atendimento</th>
                    <th>Status</th>
                    <th>Observações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!atendimentos.length">
                    <td colspan="5" class="text-center">Nenhum atendimento registrado</td>
                  </tr>
                  <tr v-for="atendimento in atendimentos" :key="atendimento.id">
                    <td>{{ atendimento.date }}</td>
                    <td>{{ atendimento.professional?.name }}</td>
                    <td>{{ atendimento.type }}</td>
                    <td>{{ atendimento.status }}</td>
                    <td>{{ atendimento.observations }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.form-control-static {
  padding: 0.5rem 0.75rem;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.card {
  margin-bottom: 1.5rem;
}

.form-label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.table th {
  font-weight: 600;
  background-color: #f8f9fa;
}
</style>